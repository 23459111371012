import { useEffect, useRef, useState } from 'react';
import CardAuth from '.';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import { useRegisterEmail, useResendRegisterOtp, useVerifyOtpEmail } from 'src/hooks';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { getClipboardText } from 'src/utils/func';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import Checkbox from '../Checkbox';
import ImageC from '../Image';
import Input from '../Input';
import InputOTP from '../InputOTP';

import classes from './index.module.less';

interface Props {
	onRecoverClick?: () => void;
	onClose?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardRegisterEmail = ({ onRecoverClick, onClose, onSuccess, updateLoadingState }: Props) => {
	const { copy } = useCopyToClipboard();
	const { setUser } = useAuthReducer();

	const {
		mutate: registerEmail,
		data: dataRegister,
		isLoading: isLoadingRegisterEmail,
	} = useRegisterEmail();
	const {
		// mutate: resendOtp,
		isLoading: isLoadingResendOtp,
		reset: resetResendOtp,
	} = useResendRegisterOtp();
	const {
		mutate: verifyOtp,
		data: dataVerifyOtp,
		isLoading: isLoadingVerifyOtp,
	} = useVerifyOtpEmail();

	const resRegister = dataRegister?.data?.data;
	const resVerifyOtp = dataVerifyOtp?.data?.data;

	const otpNumberOfDigits = 6;
	const otpExpiryTime = parseInt(process.env.NEXT_PUBLIC_OTP_EXPIRED_DURATION as string); // in seconds

	const [step, setStep] = useState<number>(1);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const [formData, setFormData] = useState<{
		email: string;
		// username: string;
		// password: string;
		// password_confirmation: string;
	}>({
		email: '',
		// username: '',
		// password: '',
		// password_confirmation: '',
	});

	const [checked, setChecked] = useState<boolean>(false);

	const [otp, setOtp] = useState(new Array(otpNumberOfDigits).fill(''));
	const [otpExpiredCountdown, setOtpExpiredCountdown] = useState<number>(0);

	const finalOtp = otp.join('');

	const otpExpiredCountdownRef = useRef(otpExpiryTime);

	let otpExpiredCountdownInterval: any = null;
	const stopOtpExpiredCountdown = () => {
		if (otpExpiredCountdownInterval !== null) {
			clearInterval(otpExpiredCountdownInterval);
			otpExpiredCountdownInterval = null;
		}
	};
	const startOtpExpiredCountdown = () => {
		stopOtpExpiredCountdown();

		otpExpiredCountdownRef.current = otpExpiryTime;
		setOtpExpiredCountdown(otpExpiredCountdownRef.current);

		otpExpiredCountdownInterval = setInterval(() => {
			if (otpExpiredCountdownRef.current === 0) {
				stopOtpExpiredCountdown();
				return;
			}
			otpExpiredCountdownRef.current = otpExpiredCountdownRef.current - 1;
			setOtpExpiredCountdown(otpExpiredCountdownRef.current);
		}, 1000);
	};

	const getCountdownTimer = () => {
		const min: string = Math.floor(otpExpiredCountdown / 60).toString();
		let sec: string = (otpExpiredCountdown % 60).toString();
		if (sec.length === 1) {
			sec = '0' + sec;
		}
		return min + ':' + sec;
	};

	// const validatePassword = () => {
	// const password = formData.password;
	// const hasUpperCase = /[A-Z]/.test(password);
	// const hasLowerCase = /[a-z]/.test(password);
	// const hasNumbers = /\d/.test(password);
	// // const hasSpecialCharacters = /[!@#\$%\^&\*]/.test(password);
	// return password.length >= 8 && (hasUpperCase || hasLowerCase) && hasNumbers;
	// };

	// const validateRePassword = () => {
	// return formData.password === formData.password_confirmation;
	// };

	const validateFormData = (data: any) => {
		if (
			data == null ||
			Object.values(data ?? [])?.find((val: any) => val.length < 3) !== undefined
		) {
			return false;
		}

		return true;
	};

	const handleFormInputChange = (e: any) => {
		const name = e.target.name;
		let value = e.target.value;
		if (name === 'username') {
			value = value.replace(/[^0-9a-z]/gi, '');
		}
		setFormData({ ...formData, [name]: value });
	};

	const handlePasteFromClickboardClick = async () => {
		let clipboardText = await getClipboardText();
		clipboardText = clipboardText.replace(/\s/g, '');

		setOtp([
			clipboardText[0] ?? '',
			clipboardText[1] ?? '',
			clipboardText[2] ?? '',
			clipboardText[3] ?? '',
			clipboardText[4] ?? '',
			clipboardText[5] ?? '',
		]);
	};

	const handleRegisterClick = (e: any) => {
		e.preventDefault();

		// if (!validatePassword()) {
		// return notify('Use 8 or more characters mixing letters and numbers', 'error');
		// } else if (!validateRePassword()) {
		// return notify('Please re-enter password correctly', 'error');
		// }

		registerEmail(
			{
				email: formData.email,
				username: '',
				password: '',
				password_confirmation: '',
				// username: formData.username,
				// password: formData.password,
				// password_confirmation: formData.password_confirmation,
			},
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						resetResendOtp();
						startOtpExpiredCountdown();
						setStep(2);
					} else {
						notify(res?.data?.message ?? 'Failed to register', 'error');
					}
				},
			},
		);
	};

	const handleResendOtpClick = () => {
		registerEmail(
			{
				email: formData.email,
				username: '',
				password: '',
				password_confirmation: '',
				// username: formData.username,
				// password: formData.password,
				// password_confirmation: formData.password_confirmation,
			},
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						startOtpExpiredCountdown();
					} else {
						notify(res?.data?.message ?? 'Failed to resend otp', 'error');
					}
				},
			},
		);
	};

	const handleVerifyOtpClick = (e: any) => {
		e.preventDefault();

		if (finalOtp.length === otpNumberOfDigits) {
			verifyOtp(
				{
					is_xellar: true,
					verify_token: resRegister.verify_token,
					email: formData.email,
					otp: finalOtp,
				},
				{
					onSuccess: (res: any) => {
						if (res.status === 200) {
							setUser({ ...res.data.data });

							if (resVerifyOtp?.secret0) {
								setStep(3);
							} else {
								onSuccess?.();
								notify(res?.data?.message ?? 'Register success');
							}
						} else {
							notify(res?.data?.message ?? 'Failed to verify otp', 'error');
						}
					},
				},
			);
		}
	};

	useEffect(() => {
		const isLoading = isLoadingRegisterEmail || isLoadingVerifyOtp;
		updateLoadingState?.(isLoading);
	}, [isLoadingRegisterEmail, isLoadingVerifyOtp]);

	useEffect(() => {
		setButtonDisabled(true);
		setOtp(new Array(otpNumberOfDigits).fill(''));
	}, [step]);

	useEffect(() => {
		if (validateFormData(formData)) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [formData]);

	useEffect(() => {
		if (finalOtp.length === otpNumberOfDigits) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [finalOtp]);

	return (
		<CardAuth showCreoplayImage={false}>
			<div className={classes.header}>
				<div>
					{step > 1 && (
						<ImageC
							alt="icon back"
							className="cursor-pointer"
							src="/icons/icon-back-white.svg"
							width={16}
							height={16}
							onClick={() => setStep(step - 1)}
						/>
					)}
				</div>
				<div>
					<ImageC
						alt="icon close"
						className="cursor-pointer"
						src="/icons/icon-close-white.svg"
						width={16}
						height={16}
						onClick={onClose}
					/>
				</div>
			</div>

			<h1 className={classes.title}>Register with Email</h1>
			{step === 1 && <h2 className={classes.description}>Connect to Creo Play</h2>}

			{step === 1 && (
				<form className={classes.form} onSubmit={handleRegisterClick}>
					<Row gutter={[30, 12]}>
						<Col span={24}>
							<Input
								type="email"
								name="email"
								placeholder="Email"
								value={formData.email}
								onChange={handleFormInputChange}
							/>
						</Col>
						{/* <Col span={24}>
							<Input
								type="text"
								name="username"
								placeholder="Username"
								autoComplete="new-username"
								value={formData.username}
								onChange={handleFormInputChange}
							/>
						</Col>
						<Col span={24}>
							<Input
								type="password"
								name="password"
								placeholder="Password"
								autoComplete="new-password"
								value={formData.password}
								onChange={handleFormInputChange}
							/>
							<span className={classes['password-rules']}>
								Use 8 or more characters mixing letters and numbers
							</span>
						</Col>
						<Col span={24}>
							<Input
								type="password"
								name="password_confirmation"
								placeholder="Re-enter Password"
								value={formData.password_confirmation}
								onChange={handleFormInputChange}
							/>
						</Col> */}
						{/* <Col span={24}>
						</Col> */}
					</Row>
					<div className={classNames(classes['create-account'], 'mt-4 text-center')}>
						Enter your email above and we will send you the verification number
					</div>
					<Button
						className={classNames(classes['submit-btn'], 'w-100 mb-4')}
						type="submit"
						loading={isLoadingRegisterEmail}
						disabled={buttonDisabled}
						style={{ marginTop: 145 }}
					>
						Send Verification
					</Button>
					<div className={classNames(classes['create-account'], 'mt-0')}>
						<span>Already have an account?</span>
						<a onClick={() => onRecoverClick?.()}>Log in</a>
					</div>
					<div className={classes.agreement}>
						By signing up you are agreeing to <br />
						our <a>Terms of Use</a> and our <a>Privacy Policy</a>
					</div>
				</form>
			)}

			{step === 2 && (
				<form
					className={classNames(classes.form, classes['form-otp'], 'mt-5')}
					onSubmit={handleVerifyOtpClick}
				>
					<InputOTP otp={otp} setOtp={setOtp} />

					<div className={classNames(classes.label, 'mt-4')}>
						<div>
							A one time verification code has been sent to{' '}
							<span className="fw-500">{formData.email}</span>
						</div>
					</div>
					{finalOtp.length === otpNumberOfDigits ? (
						<Button
							style={{ marginTop: 214 }}
							className={classNames('w-100', classes['submit-btn'])}
							type="submit"
							loading={isLoadingVerifyOtp}
							disabled={buttonDisabled}
						>
							Verify
						</Button>
					) : (
						<Button
							style={{ marginTop: 214 }}
							className={classNames('w-100', classes['submit-btn'])}
							onClick={handlePasteFromClickboardClick}
						>
							Paste from Clipboard
						</Button>
					)}

					<div className={classNames(classes.label, 'fw-400')}>
						<div>
							Expires in <span className="fw-500">{getCountdownTimer()}</span>
						</div>
						<a
							className={classNames({ disabled: isLoadingResendOtp || otpExpiredCountdown !== 0 })}
							onClick={handleResendOtpClick}
						>
							Resend code
						</a>
					</div>
				</form>
			)}

			{step === 3 && (
				<>
					<div className={classNames('w-100 d-flex flex-column align-items-center mt-5 pt-2')}>
						<ImageC src="/icons/icon-recovery-key.svg" width={88} height={88} />
						<span className="fs-12 fw-400 mt-4 mb-3 opacity-80">
							Please keep this recovery code below!
						</span>
						<div className="d-flex">
							<div
								className={classNames(classes['recovery-key'], 'mb-5')}
								onClick={() => copy(resVerifyOtp?.secret0)}
							>
								{resVerifyOtp?.secret0}
							</div>
							{/* <ButtonCopy clipboard={resVerifyOtp?.secret0} buttonType="light" /> */}
						</div>
						<Checkbox
							size={16}
							label={
								<span className="fs-12 fw-400 opacity-80">Yes I have saved the Recovery Code</span>
							}
							checkedIcon="check"
							checked={checked}
							onChange={(e) => setChecked(e.target.checked)}
						/>
						<Button
							className={classNames('w-100 mt-4', classes['submit-btn'])}
							disabled={!checked}
							onClick={onSuccess}
						>
							Enter the Creoplay
						</Button>
					</div>
				</>
			)}
		</CardAuth>
	);
};

export default CardRegisterEmail;
