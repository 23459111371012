import CardAuth from '.';

import Button from '../Button';
import ImageC from '../Image';
import Spinner from '../Spinner';

import classes from './index.module.less';

interface Props {
	onSignupWhatsappClick?: () => void;
	onSignupTelegramClick?: () => void;
	onCreateAccountClick?: () => void;
	onLoginClick?: () => void;
	onSuccess?: () => void;
	loading?: boolean;
	updateLoadingState?: (val: boolean) => void;
}

const CardSignUp = ({
	onSignupWhatsappClick,
	// onSignupTelegramClick,
	onCreateAccountClick,
	onLoginClick,
	// onSuccess,
	loading,
	// updateLoadingState,
}: Props) => {
	// const { setUser } = useAuthReducer();
	// const { web3CheckSubscription } = useWeb3Subscription();

	// const { mutate: loginTelegram, isLoading: isLoadingLoginTelegram } = useLoginTelegram();

	const isLoading = loading;
	// || isLoadingLoginTelegram;

	const handleSignupEmailClick = () => {
		onCreateAccountClick?.();
	};

	// const handleSignupTelegramClick = () => {
	// // onSignupTelegramClick?.();

	// updateLoadingState?.(true);

	// (window as any)?.Telegram?.Login?.auth(
	// { bot_id: process.env.NEXT_PUBLIC_TELEGRAM_BOT_ID, request_access: true },
	// (data: any) => {
	// // console.log(data);

	// if (!data) {
	// // authorization failed
	// notify('Telegram authorization cancelled', 'error');
	// updateLoadingState?.(false);
	// return;
	// }

	// loginTelegram(
	// { ...data },
	// {
	// onSuccess: async (res: any) => {
	// if (res.status === 200 && res?.data?.data) {
	// const subscription = await web3CheckSubscription(address, currentChainId);
	// let result = { ...res.data.data };

	// if (subscription) {
	// result = { ...result, subscription };
	// }

	// setUser(result);

	// notify('Register success!');
	// onSuccess?.();
	// updateLoadingState?.(false);
	// } else {
	// // error wallet auth
	// notify('Failed to register with telegram.', 'error');
	// updateLoadingState?.(false);
	// }
	// },
	// },
	// );
	// },
	// );
	// };

	const handleSignupWhatsappClick = () => {
		onSignupWhatsappClick?.();
	};

	return (
		<Spinner spinning={isLoading}>
			<CardAuth>
				<h1 className={classes.title}>Welcome</h1>
				<h2 className={classes.description}>Sign up to Creo Play </h2>
				<div className={classes['login-options']}>
					<Button
						className={classes['login-opt-button']}
						onClick={() => handleSignupWhatsappClick()}
					>
						<ImageC alt="icon wallet" src="/icons/whatsapp.svg" height={20} width={20} />
						<span>Sign Up via Whatsapp</span>
					</Button>
					{/* <Button
						className={classes['login-opt-button']}
						onClick={() => handleSignupTelegramClick()}
					>
						<ImageC alt="icon wallet" src="/icons/telegram.svg" height={20} width={20} />
						<span>Sign Up via Telegram</span>
					</Button> */}
					<Button className={classes['login-opt-button']} onClick={() => handleSignupEmailClick()}>
						<ImageC alt="icon wallet" src="/icons/mail.svg" height={20} width={20} />
						<span>Sign Up via Email</span>
					</Button>
				</div>
				<div className={classes['create-account']}>
					<span>Already have an account?</span>
					<a onClick={() => onLoginClick?.()}>Log in</a>
				</div>
			</CardAuth>
		</Spinner>
	);
};

export default CardSignUp;
