import { useEffect, useRef, useState } from 'react';
import CardAuth from '.';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import { useRegisterWhatsapp, useResendOtpWhatsapp, useVerifyOtpWhatsapp } from 'src/hooks';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { getClipboardText } from 'src/utils/func';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import Checkbox from '../Checkbox';
import ImageC from '../Image';
import Input from '../Input';
import InputOTP from '../InputOTP';

import classes from './index.module.less';

interface Props {
	onRecoverClick?: () => void;
	onClose?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardRegisterWhatsapp = ({
	onRecoverClick,
	onClose,
	onSuccess,
	updateLoadingState,
}: Props) => {
	const { copy } = useCopyToClipboard();
	const { setUser } = useAuthReducer();

	const {
		mutate: register,
		data: dataRegister,
		isLoading: isLoadingRegister,
	} = useRegisterWhatsapp();
	const {
		mutate: verifyOtp,
		data: dataVerifyOtp,
		isLoading: isLoadingVerifyOtp,
	} = useVerifyOtpWhatsapp();
	const {
		mutate: resendOtp,
		data: dataResendOtp,
		isLoading: isLoadingResendOtp,
		reset: resetResendOtp,
	} = useResendOtpWhatsapp();

	const resRegister = dataRegister?.data?.data;
	const resVerifyOtp = dataVerifyOtp?.data?.data;
	const resResendOtp = dataResendOtp?.data?.data;

	const isLoading = isLoadingRegister || isLoadingVerifyOtp;

	const otpNumberOfDigits = 6;
	const otpExpiryTime = parseInt(process.env.NEXT_PUBLIC_OTP_EXPIRED_DURATION as string); // in seconds

	const [step, setStep] = useState<number>(1);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const [formData, setFormData] = useState<{
		code: string;
		number: string;
	}>({
		code: '+62',
		number: '',
	});
	const [otp, setOtp] = useState(new Array(otpNumberOfDigits).fill(''));
	const [otpExpiredCountdown, setOtpExpiredCountdown] = useState<number>(0);

	const [checked, setChecked] = useState<boolean>(false);

	const finalOtp = otp.join('');

	const otpExpiredCountdownRef = useRef(otpExpiryTime);

	let otpExpiredCountdownInterval: any = null;
	const stopOtpExpiredCountdown = () => {
		if (otpExpiredCountdownInterval !== null) {
			clearInterval(otpExpiredCountdownInterval);
			otpExpiredCountdownInterval = null;
		}
	};
	const startOtpExpiredCountdown = () => {
		stopOtpExpiredCountdown();

		otpExpiredCountdownRef.current = otpExpiryTime;
		setOtpExpiredCountdown(otpExpiredCountdownRef.current);

		otpExpiredCountdownInterval = setInterval(() => {
			if (otpExpiredCountdownRef.current === 0) {
				stopOtpExpiredCountdown();
				return;
			}
			otpExpiredCountdownRef.current = otpExpiredCountdownRef.current - 1;
			setOtpExpiredCountdown(otpExpiredCountdownRef.current);
		}, 1000);
	};

	const getCountdownTimer = () => {
		const min: string = Math.floor(otpExpiredCountdown / 60).toString();
		let sec: string = (otpExpiredCountdown % 60).toString();
		if (sec.length === 1) {
			sec = '0' + sec;
		}
		return min + ':' + sec;
	};

	const validateFormData = (data: any) => {
		if (
			data == null ||
			Object.values(data ?? [])?.find((val: any) => val.length < 3) !== undefined
		) {
			return false;
		}

		return true;
	};

	const handleFormInputChange = (e: any) => {
		const name = e.target.name;
		let value = e.target.value;
		// if (name === 'username') {
		// value = value.replace(/[^0-9a-z]/gi, '');
		// }

		if (name === 'code') {
			if (value.length > 5) {
				return;
			} else {
				// Removing every non-digit character except a plus (+) sign
				value = value.replace(/[^\d\+]+/g, '').replace(/(.)\++/g, '$1');
			}
		} else if (name === 'number') {
			if (value.length > 15) {
				return;
			}
		}
		setFormData({ ...formData, [name]: value });
	};

	const handlePasteFromClickboardClick = async () => {
		let clipboardText = await getClipboardText();
		clipboardText = clipboardText.replace(/\s/g, '');

		setOtp([
			clipboardText[0] ?? '',
			clipboardText[1] ?? '',
			clipboardText[2] ?? '',
			clipboardText[3] ?? '',
			clipboardText[4] ?? '',
			clipboardText[5] ?? '',
		]);
	};

	const handleConnectClick = (e: any) => {
		e.preventDefault();

		const phoneNumber = `${formData.code}${formData.number}`.replaceAll('+', '');

		register(
			{ phone_number: phoneNumber },
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						resetResendOtp();
						startOtpExpiredCountdown();
						setStep(2);
					} else {
						notify(res?.data?.message ?? 'Failed to register whatsapp', 'error');
					}
				},
			},
		);
	};

	const handleResendOtpClick = () => {
		resendOtp(
			{ verify_token: resRegister.verify_token, creo_id: resRegister.creo_id },
			{
				onSuccess: (res: any) => {
					if (res.status === 200) {
						startOtpExpiredCountdown();
					} else {
						notify(res?.data?.message ?? 'Failed to resend otp', 'error');
					}
				},
			},
		);
	};

	const handleVerifyOtpClick = (e: any) => {
		e.preventDefault();

		if (finalOtp.length === otpNumberOfDigits) {
			const verifyToken = resResendOtp?.verify_token ?? resRegister?.verify_token;

			const phoneNumber = `${formData.code}${formData.number}`.replaceAll('+', '');

			verifyOtp(
				{
					verify_token: verifyToken,
					creo_id: resRegister.creo_id,
					otp: finalOtp,
					phone_number: phoneNumber,
				},
				{
					onSuccess: (res: any) => {
						if (res.status === 200) {
							setUser({ ...res.data.data });

							if (resVerifyOtp?.secret0) {
								setStep(3);
							} else {
								onSuccess?.();
								notify(res?.data?.message ?? 'Register success');
							}
						} else {
							notify(res?.data?.message ?? 'Failed to register whatsapp', 'error');
						}
					},
				},
			);
		}
	};

	useEffect(() => {
		updateLoadingState?.(isLoading);
	}, [isLoading]);

	useEffect(() => {
		setButtonDisabled(true);
		setOtp(new Array(otpNumberOfDigits).fill(''));
	}, [step]);

	useEffect(() => {
		if (validateFormData(formData)) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [formData]);

	useEffect(() => {
		if (finalOtp.length === otpNumberOfDigits) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [finalOtp]);

	return (
		<CardAuth showCreoplayImage={false}>
			<div className={classes.header}>
				<div>
					{step > 1 && step < 3 && (
						<ImageC
							alt="icon back"
							className="cursor-pointer"
							src="/icons/icon-back-white.svg"
							width={16}
							height={16}
							onClick={() => setStep(step - 1)}
						/>
					)}
				</div>
				<div>
					<ImageC
						alt="icon close"
						className="cursor-pointer"
						src="/icons/icon-close-white.svg"
						width={16}
						height={16}
						onClick={onClose}
					/>
				</div>
			</div>

			{step === 1 && (
				<>
					<h1 className={classes.title}>Whatsapp Register</h1>
					<h2 className={classes.description}>Connect to Creo Play</h2>
				</>
			)}
			{step !== 1 && (
				<h1 className={classes.title}>
					<ImageC src="/icons/whatsapp-green.png" width={30} height={30} />
					Whatsapp Register
				</h1>
			)}

			{step === 1 && (
				<>
					<form className={classNames(classes.form, 'pt-4 mb-0')} onSubmit={handleConnectClick}>
						<Row gutter={[30, 12]}>
							<Col span={24}>
								<div className={classes['phone-number']}>
									<div
										className={classes.code}
										style={{
											width: `calc(${formData?.code?.toString()?.length ?? 0}ch + 38px)`,
										}}
									>
										<Input
											name="code"
											placeholder="+62"
											// defaultValue={'+62'}
											value={formData.code}
											onChange={handleFormInputChange}
										/>
									</div>
									<div className={classes.line}></div>
									<div className={classes.number}>
										<Input
											className={classes.number}
											type="number"
											name="number"
											placeholder=""
											value={formData.number}
											onChange={handleFormInputChange}
										/>
									</div>
								</div>
							</Col>
						</Row>
						<div className={classNames(classes['create-account'], 'mt-4 text-center')}>
							Enter your Whatsapp number above and we will send you the verification number
						</div>
						<Button
							className={classNames(classes['submit-btn'], 'w-100 mb-4')}
							type="submit"
							loading={isLoading}
							// disabled={buttonDisabled}
							style={{ marginTop: 145 }}
						>
							Join Creoplay
						</Button>
						<div className={classNames(classes['create-account'], 'mt-0')}>
							<span>Already have an account?</span>
							<a onClick={() => onRecoverClick?.()}>Log in</a>
						</div>
						<div className={classes.agreement}>
							By signing up you are agreeing to <br />
							our <a>Terms of Use</a> and our <a>Privacy Policy</a>
						</div>
					</form>
				</>
			)}

			{step === 2 && (
				<form
					className={classNames(classes.form, classes['form-otp'], 'pt-4')}
					onSubmit={handleVerifyOtpClick}
				>
					<InputOTP otp={otp} setOtp={setOtp} />

					<div className={classNames(classes.label, 'mt-4')}>
						<div>
							Enter the verification code that has been sent too{' '}
							<span className="fw-500">{formData.code + formData.number}</span>
						</div>
					</div>
					{finalOtp.length === otpNumberOfDigits ? (
						<Button
							className={classNames('w-100', classes['submit-btn'])}
							type="submit"
							loading={isLoading}
							disabled={buttonDisabled}
						>
							Continue
						</Button>
					) : (
						<Button
							className={classNames('w-100', classes['submit-btn'])}
							onClick={handlePasteFromClickboardClick}
						>
							Paste from Clipboard
						</Button>
					)}

					<div className={classNames(classes.label, 'fw-400')}>
						<div>
							Expires in <span className="fw-500">{getCountdownTimer()}</span>
						</div>
						<a
							className={classNames({ disabled: isLoadingResendOtp || otpExpiredCountdown !== 0 })}
							onClick={handleResendOtpClick}
						>
							Resend code
						</a>
					</div>
				</form>
			)}

			{step === 3 && (
				<>
					<div className={classNames('w-100 d-flex flex-column align-items-center mt-5 pt-2')}>
						<ImageC src="/icons/icon-recovery-key.svg" width={88} height={88} />
						<span className="fs-12 fw-400 mt-4 mb-3 opacity-80">
							Please keep this recovery code below!
						</span>
						<div
							className={classNames(classes['recovery-key'], 'mb-5')}
							onClick={() => copy(resVerifyOtp?.secret0)}
						>
							{resVerifyOtp?.secret0}
						</div>
						<Checkbox
							size={16}
							label={
								<span className="fs-12 fw-400 opacity-80">Yes I have saved the Recovery Code</span>
							}
							checkedIcon="check"
							checked={checked}
							onChange={(e) => setChecked(e.target.checked)}
						/>
						<Button
							className={classNames('w-100 mt-4', classes['submit-btn'])}
							disabled={!checked}
							onClick={onSuccess}
						>
							Enter the Creoplay
						</Button>
					</div>
				</>
			)}
		</CardAuth>
	);
};

export default CardRegisterWhatsapp;
